.landing-page {
    background-image: url('../images/Home_page_banner.png');
    background-size: cover;
    background-position: center;
    height: 100vh;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .landing-page-container {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  .landing-page-text {
    margin: 20px 0;
  }
  
  .landing-page-button {
    margin-top: 20px;
  }
  
  .footer {
    background-color: #5770c2;
    padding: 20px 0;
    text-align: center;
    margin-top: 40px;
  }
  
  .footer .MuiGrid-container {
    justify-content: center;
  }
  
  .footer .MuiGrid-item {
    text-align: left;
  }

  /* model  */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 40px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px; /* Ensures it doesn't exceed 600px */
    height: 80%;
    max-height: 200px; /* Ensures it doesn't exceed 600px */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: auto; /* Adds scroll if content overflows */
  }
  
  .close-button {
    background: transparent;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  /* Media Queries */
  @media (max-width: 768px) {
    .modal-content {
      width: 90%;
      height: 90%;
    }
  }
  
  @media (max-width: 480px) {
    .modal-content {
      width: 95%;
      height: 95%;
      padding: 20px; /* Reduce padding for small screens */
    }
  }
  